import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PageContainer from '../components/PageContainer'
import RichText from '../components/RichText'

interface MarkdownRemark {
  html: string
}

interface Data {
  markdownRemark: MarkdownRemark
}

interface Props {
  data: Data
}

export default function Document({ data }: Props): JSX.Element {
  return (
    <Layout>
      <PageContainer sx={{ py: '3rem' }}>
        <RichText content={data.markdownRemark.html} />
      </PageContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      html
    }
  }
`
